<template>
  <v-card :loading="loading" outlined elevation="1" class="stat-container" @click="routeTo()">
    <v-card-title class="text-center">
      <div class="text-center">
        {{ nome }}
      </div>
    </v-card-title>
    <div class="text-center text-h4">{{ num }}</div>
  </v-card>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    routeTo() {
      if (this.clickable) {
        this.$router.push(`/app/moderador/${this.to}`);
      }
    },
  },
  props: {
    nome: String,
    to: String,
    num: {
      type: Number,
      default: 0,
    },
    loading: Boolean,
    clickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.stat-container{
  min-height: 150px;
  height: auto;
}
</style>