<template>
  <v-container fluid>
    <v-row>
      <v-img contain width="50px" height="120px" src="@/assets/binoculo.svg"></v-img>
    </v-row>
    <v-row>
      <v-col cols="6">
        <stat-container :loading="loading2" to="cadastros" nome="Colaborades cadastrados" :num="usuarios.length"
          :clickable="true" :userData="userData" />
      </v-col>
      <v-col cols="6">
        <stat-container clickable :loading="loading" to="denuncias-analisar" :num="denunciasAnalisar.length"
          nome="Denúncias para analisar" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <stat-container to="denuncias-encaminhadas" :num="0" nome="Denúncias encaminhadas" />
      </v-col>
      <v-col cols="6">
        <stat-container clickable :loading="loading" nome="Denúncias publicadas" to="denuncias-publicadas"
          :num="denunciasPublicadas.length" />
      </v-col>
    </v-row>
    <div class="export-div">
      <div @click="goTo()" class="click-txt">
        Exportar dados
      </div>
    </div>
  </v-container>
</template>
<script>
import StatContainer from "./StatContainer.vue";
export default {
  components: { StatContainer },
  data() {
    return {};
  },
  computed: {
    denunciasAnalisar() {
      return this.denuncias.filter((x) => !x.publicado);
    },
    denunciasPublicadas() {
      return this.denuncias.filter((x) => x.publicado);
    },
  },
  methods: {
    goTo() {
      this.$router.push(`/app/moderador/exportar`);
    }
  },
  props: {
    denuncias: Array,
    usuarios: Array,
    loading: Boolean,
    loading2: Boolean,
    userData: Object,
  },
};
</script>
<style scoped>
.export-div {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.click-txt {
  cursor: pointer;
  text-decoration: underline;
}

.click-txt:hover {
  color: blue;
}
</style>